<!-- =========================================================================================
    File Name: Invoice.vue
    Description: Invoice Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="invoice-page">

        <div class="flex flex-wrap items-center justify-between">
          <vx-input-group class="mb-base mr-3">
            <vs-button v-if="data.status==1" size="small" icon-pack="feather" :to='{name:"invoices-edit", params : {id : data.id}}' icon="icon-edit">Edit</vs-button>
          </vx-input-group>
          <div class="flex items-center">
            <vs-button class="mb-base mr-3" @click="approve" v-if="data.status==1" color="success" size="small" icon-pack="feather" icon="icon icon-check">APPROVE</vs-button>
            <!-- <vs-button class="mb-base mr-3" icon-pack="feather" icon="icon icon-file" @click="printInvoice">Print</vs-button> -->
          </div>
        </div>

        <vx-card id="invoice-container">

            <!-- INVOICE METADATA -->
            <div class="vx-row leading-loose p-base">
                <div class="vx-col w-1/2 mt-base">
                    <img :src="setting.data.logo" alt="vuexy-logo" style="width: 200px;">
                </div>
                <div class="vx-col w-1/2 text-right">
                    <h1>Invoice</h1>
                    <div class="invoice__invoice-detail mt-6">
                        <h6>INVOICE NO.</h6>
                        <p class="font-semibold">{{ data.code }}</p>

                        <h6 class="mt-4">INVOICE DATE</h6>
                        <p>{{ data.date_transaction | moment('Do MMM YYYY') }}</p>
                    </div>
                </div>
                <div class="vx-col w-1/2 mt-12">
                    <h5>Recipient</h5>
                    <div class="invoice__recipient-info my-4">
                        <p class="font-bold">{{ recipientDetails.name }}</p>
                        <p>{{ recipientDetails.address }}</p>
                        <p>{{ recipientDetails.phone }}</p>
                    </div>
                </div>
                <div class="vx-col w-1/2 mt-base text-right mt-12">
                    <h5>{{ companyDetails.name }}</h5>
                    <div class="invoice__company-info my-4">
                        <p>{{ companyDetails.address }}</p>
                    </div>
                    <div class="invoice__company-contact">
                        <p class="flex items-center justify-end">
                            <feather-icon icon="MailIcon" svgClasses="h-4 w-4"></feather-icon>
                            <span class="ml-2">{{ companyDetails.email }}</span>
                        </p>
                        <p class="flex items-center justify-end">
                            <feather-icon icon="PhoneIcon" svgClasses="h-4 w-4"></feather-icon>
                            <span class="ml-2">{{ companyDetails.phone }}</span>
                        </p>
                    </div>

                </div>
            </div>

            <!-- INVOICE CONTENT -->
            <div class="p-base">
                <!-- INVOICE TASKS TABLE -->
                <vs-table hoverFlat :data="details">
                    <!-- HEADER -->
                    <template slot="thead">
                        <vs-th class="pointer-events-none">DESCRIPTION</vs-th>
                        <vs-th class="pointer-events-none">QTY</vs-th>
                        <vs-th class="pointer-events-none">PRICE</vs-th>
                        <vs-th class="pointer-events-none">DISCOUNT</vs-th>
                        <vs-th class="pointer-events-none">TAX</vs-th>
                        <vs-th class="pointer-events-none">SUBTOTAL</vs-th>
                    </template>

                    <!-- DATA -->
                    <template slot-scope="{data}">
                        <vs-tr v-for="(tr, index) in data" :key="index">
                          <template v-if="tr.job_order_id">
                            <vs-td>
                              <!-- <p class="font-semibold"></p> -->
                              <p class="font-semibold">{{tr.route}}</p>
                              <p class="font-semibold">{{tr.mode}}</p>
                              <p class="font-semibold">{{tr.vehicle_type}}</p>
                              <p v-for="(val, i) in tr.detail" :key="i"><small>- {{val}}</small></p>
                            </vs-td>
                            <vs-td class='text-right' label='qty'>{{tr.qty|numFormat}}</vs-td>
                            <vs-td class='text-right' label='price'>Rp. {{tr.price|numFormat}}</vs-td>
                            <vs-td class='text-right' label='discount'>Rp. {{tr.discount|numFormat}}</vs-td>
                            <vs-td class='text-right' label='tax'>Rp. {{tr.tax_total|numFormat}}</vs-td>
                            <vs-td class='text-right' label='subtotal'>Rp. {{tr.total_price|numFormat}}</vs-td>
                          </template>
                        </vs-tr>
                    </template>
                </vs-table>

                <!-- INVOICE SUMMARY TABLE -->
                <table hoverFlat class="w-1/4 ml-auto mt-4">
                    <vs-tr>
                        <vs-th class="pointer-events-none">SUBTOTAL</vs-th>
                        <vs-td class='text-right'>{{ data.subtotal|numFormat }}</vs-td>
                    </vs-tr>
                    <vs-tr>
                        <vs-th class="pointer-events-none">DISCOUNT</vs-th>
                        <vs-td class='text-right'>{{ data.discount|numFormat }}</vs-td>
                    </vs-tr>
                    <vs-tr>
                        <vs-th class="pointer-events-none">TAX</vs-th>
                        <vs-td class='text-right'>{{ data.tax|numFormat }}</vs-td>
                    </vs-tr>
                    <vs-tr>
                        <vs-th class="pointer-events-none">TOTAL</vs-th>
                        <vs-td class="font-semibold text-right">{{ data.grand_total|numFormat }}</vs-td>
                    </vs-tr>
                </table>
            </div>

            <!-- INVOICE FOOTER -->
            <div class="invoice__footer text-right p-base">
                <p class="mb-4">Transfer the amounts to the business amount below. Please include invoice number on your check.</p>
                <p>
                    <span class="mr-8">BANK: <span class="font-semibold">FTSBUS33</span></span>
                    <span>IBAN: <span class="font-semibold"> G882-1111-2222-3333 </span></span>
                </p>
            </div>
        </vx-card>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default{
  data () {
    return {
      mailTo: '',
      setting: {},
      data: {},
      details:[],
      companyDetails: {},
      recipientDetails: {},
      subtotal: 0,
      discount: 0
    }
  },
  computed: {

  },
  methods: {
    ...mapActions({
      dispatchApprove: `invoices/approve`,
      dispatchShow: `invoices/show`,
      dispatchShowDetail: `invoices/show_detail`,
      dispatchShowCompany: `companies/show`,
      dispatchShowContact: `contacts/show`,
      dispatchShowApplication: `application/show`
    }),
    printInvoice () {
      window.print()
    },
    approve(){
      this.$vs.dialog({
        type: `confirm`,
        color: `success`,
        title: `Are you sure ?`,
        text: `Invoice will be approved`,
        accept: this.confirmApprove
      })
    },
    async confirmApprove(){
      await this.dispatchApprove(this.$route.params.id)
      this.getData()
    },
    async getData(){
      this.$vs.loading()
      const setting = await this.dispatchShowApplication('general')
      const item = await this.dispatchShow(this.$route.params.id)
      const detail = await this.dispatchShowDetail(this.$route.params.id)
      const co = await this.dispatchShowCompany(item.data.company_id)
      const bi = await this.dispatchShowContact(item.data.contact_bill_id)
      this.$vs.loading.close()
      this.setting = setting.data
      this.data = item.data
      this.companyDetails = co.data
      this.recipientDetails = bi.data
      this.details = detail.data
      // console.log(detail)
    }
  },
  mounted () {
    this.$emit('setAppClasses', 'invoice-page')
    this.getData()
  }
}
</script>

<style lang="scss">
@media print {
  .invoice-page {
    * {
      visibility: hidden;
    }

    #content-area {
      margin: 0 !important;
    }

    .vs-con-table {
      .vs-con-tbody {
        overflow: hidden !important;
      }
    }

    #invoice-container,
    #invoice-container * {
      visibility: visible;
    }
    #invoice-container {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
  }
}

@page {
  size: auto;
}
</style>
